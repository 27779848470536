import React, { memo } from 'react';
import {
  StudyingFormatBenefit,
} from '@/components/homepageLanding/StudyingFormatsSection/StudyingFormatsSection.typedefs';
import styles from '@/components/homepageLanding/StudyingFormatsSection/StudyingFormatsSection.module.scss';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

interface Props {
  benefits: StudyingFormatBenefit[];
}

export const StudyingFormatsBenefitsList = memo<Props>((props) => {
  const {
    benefits,
  } = props;

  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <>
      {benefits.map(({ translateCode, Icon }) => (
        <li key={translateCode} className={styles.benefitItem} data-qa="studying-benefits">
          <span className={styles.benefitIcon}>
            <Icon />
          </span>

          <p className={typography.landingTextMain}>
            {t(`${I18N_CODES.home}:${translateCode}`)}
          </p>
        </li>
      ))}
    </>
  );
});
